<template>
  <div>
    <h1 class="heading">Segment statistics</h1>
    <ObjectFilter
        :object_id="$store.state.statistics.object_id"
        :objects="objects.list"
        @changeObject="changeObject"
        @changeDates="changeDates"
        @reloadData="reloadData"
    />

    <v-tabs>
      <v-tab>All Visits</v-tab>
      <v-tab>Browsers</v-tab>
      <v-tab>Languages</v-tab>
      <v-tab>Platforms</v-tab>
      <v-tab-item>
        <div class="statistics">
          <div class="statistics__statistics" :style="`width:${isMobile?'100%':'calc(66.66% + 20px)'};`">
            <v-autocomplete
                class="statistics__type"
:style="`width:${isMobile?'150px':'320px'};`"
                solo
                flat
                dense
                outlined
                :placeholder="$t('statistics[\'Открытия\']')"
                :items="
                  Object.keys(segmentsListWithData).map((key) => ({ segment: key, name: key }))
                "
                item-value="page"
                item-text="name"
                v-model="current_segment"
                @change="changeCurrentSegment"
            >
              <template v-slot:selection="{ item }">
                {{ segmentName(item.segment) }}
              </template>
              <template v-slot:item="{ item }">
                {{ segmentName(item.segment) }}
              </template>
            </v-autocomplete>
            <div class="statistics__chart">
              <Chart
                  style="height:415px"
                  :height="415"
                  :data="chart_data"
                  :labels="dateArr"
                  :pointRadius="4"
                  :xAxes_ticks="true"
              />
            </div>
          </div>
          <div class="statistics__info"
v-if="!isMobile">
            <h3 class="statictics__caption">
              Users with segment for period
            </h3>
            <p class="statistics__period">
              <span>{{ all_visitors }}</span>
              {{ $t('statistics["за текущий период"]') }}
            </p>
            <p class="statistics__total">
              <span
              >The graph shows the number of visits with segments to your mobile
                application: {{ hotelName }}</span
              >
            </p>
          </div>
        </div>
        <div style="margin-bottom: 15px">
          <v-data-table
              class="table"
              :headers="segmentTable.headers"
              :items="segmentTable.items"
              hide-default-footer
              :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="segmentTable.items.length">
              <tr v-for="(item, i) in items" :key="i">
                <td class="d-block d-sm-table-cell">
                  {{
                  item.segment
                  }}
                </td>
                <td class="d-block d-sm-table-cell">
                  {{
                  item.value
                  }}
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td
                    class="d-block d-sm-table-cell"
                    colspan="3"
                    style="
                      text-align: center;
                      padding: 0 15px;
                      font-weight: normal;
                    "
                >
                  Sorry no data for you
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
        <v-btn color="primary" outlined small @click="downloadStatistics">
          Download csv
        </v-btn>
      </v-tab-item>
      <v-tab-item>
        <div class="statistics">
          <div class="statistics__statistics" :style="`width:${isMobile?'100%':'calc(66.66% + 20px)'};`">
            <v-select
                class="statistics__type"
:style="`width:${isMobile?'150px':'320px'};`"
                solo
                flat
                dense
                outlined
                :placeholder="$t('statistics[\'Открытия\']')"
                :items="
                Object.keys(browsers).map((key) => ({
                  browser: key,
                  name: key,
                }))
              "
                item-value="browser"
                item-text="name"
                v-model="current_browser"
                @change="changeBrowser"
            >
              <template v-slot:selection="{ item }">
                <div v-if="item.browser === 'All'">
                  {{ item.browser }}
                </div>
                <div style="display: flex; align-items: center" v-else>
                  <img
                      :src="
                      require(`@/assets/browser_images/${item.browser.toLowerCase()}.png`)
                    "
                      style="width: 25px; height: 25px; margin-right: 10px"
                      :alt="item.browser"
                  />
                  <div>{{ item.browser }}</div>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div v-if="item.browser === 'All'">
                  {{ item.browser }}
                </div>
                <div style="display: flex; align-items: center" v-else>
                  <img
                      :src="
                      require(`@/assets/browser_images/${item.browser.toLowerCase()}.png`)
                    "
                      style="width: 25px; height: 25px; margin-right: 10px"
                      :alt="item.browser"
                  />
                  <div>{{ item.browser }}</div>
                </div>
              </template>
            </v-select>
            <div class="statistics__chart">
              <Chart
                  style="height:415px"
                  :height="415"
                  :data="browsers[current_browser]"
                  :labels="dateArr"
                  :pointRadius="4"
                  :xAxes_ticks="true"
              />
            </div>
          </div>
          <div class="statistics__info"
v-if="!isMobile">
            <h3 class="statictics__caption">
              Users by browser with segment for period
            </h3>
            <p class="statistics__period">
              <span>{{ visitors_browser_statistics }}</span>
              {{ $t('statistics["за текущий период"]') }}
            </p>
            <p class="statistics__total">
              <span
              >The graph shows the number of visits by browser with segments to your mobile
                application: {{ hotelName }}</span
              >
            </p>
          </div>
        </div>
        <div style="margin-bottom: 15px">
          <v-data-table
              class="table"
              :headers="browser_statistics.headers"
              :items="browserStatisticsItems"
              hide-default-footer
              :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="browserStatisticsItems.length">
              <tr v-for="(item, i) in items" :key="i">
                <td class="d-block d-sm-table-cell" style="cursor: pointer">
                  <div style="display: flex; align-items: center">
                    <img
                        :src="
                          require(`@/assets/browser_images/${item.browser.toLowerCase()}.png`)
                        "
                        style="width: 25px; height: 25px; margin-right: 10px"
                        :alt="item.browser"
                    />
                    <div>{{ item.browser }}</div>
                  </div>
                </td>
                <td class="d-block d-sm-table-cell">
                  {{ item.value }}
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td
                    class="d-block d-sm-table-cell"
                    colspan="3"
                    style="
                      text-align: center;
                      padding: 0 15px;
                      font-weight: normal;
                    "
                >
                  Sorry no data for you
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="statistics">
          <div class="statistics__statistics" :style="`width:${isMobile?'100%':'calc(66.66% + 20px)'};`">
            <v-autocomplete
                class="statistics__type"
:style="`width:${isMobile?'150px':'320px'};`"
                solo
                flat
                dense
                outlined
                placeholder="Change browser language"
                :items="
                Object.keys(browser_langs).map((key) => ({
                  lang: key,
                  name: key,
                }))
              "
                item-value="lang"
                item-text="name"
                v-model="current_browser_lang"
                @change="changeLanguage"
            >
              <template v-slot:selection="{ item }">
                <div v-if="item.lang === 'All'">
                  {{ item.lang }}
                </div>
                <div style="display: flex; align-items: center" v-else>
                  <img
                      :src="`https://guest.mobi/3x2/${changeFormatLangPic(
                      item.lang
                    )}.svg`"
                      style="width: 25px; height: 25px; margin-right: 10px"
                      :alt="item.lang"
                  />
                  <div v-html="changeFormat(item.lang)"></div>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div v-if="item.lang === 'All'">
                  {{ item.lang }}
                </div>
                <div style="display: flex; align-items: center" v-else>
                  <img
                      :src="`https://guest.mobi/3x2/${changeFormatLangPic(
                      item.lang
                    )}.svg`"
                      style="width: 25px; height: 25px; margin-right: 10px"
                      :alt="item.lang"
                  />
                  <div v-html="changeFormat(item.lang)"></div>
                </div>
              </template>
            </v-autocomplete>
            <div class="statistics__chart">
              <Chart
                  style="height:415px"
                  :height="415"
                  :data="browsers[current_browser_lang]"
                  :labels="dateArr"
                  :pointRadius="4"
                  :xAxes_ticks="true"
              />
            </div>
          </div>
          <div class="statistics__info"
v-if="!isMobile">
            <h3 class="statictics__caption">
              {{ $t('statistics["Количество посещений"]') }}
            </h3>
            <p class="statistics__period">
              <span>{{ visitors_browser_lang_statistics }}</span>
              for {{ current_browser_lang }}
            </p>
            <p class="statistics__total">
              <span
              >The graph shows what language your guests speak, what is their
                main language</span
              >
            </p>
          </div>
        </div>
        <div style="margin-bottom: 15px">
          <v-data-table
              class="table"
              :headers="browser_lang_statistics.headers"
              :items="languagesStatisticsItems"
              hide-default-footer
              :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="languagesStatisticsItems.length">
              <tr v-for="(item, i) in items" :key="i">
                <td class="d-block d-sm-table-cell" style="cursor: pointer">
                  <div style="display: flex; align-items: center">
                    <img
                        :src="`https://guest.mobi/3x2/${changeFormatLangPic(
                          item.browser_lang
                        )}.svg`"
                        style="width: 25px; height: 25px; margin-right: 10px"
                        :alt="item.browser_lang"
                    />
                    <div v-html="changeFormat(item.browser_lang)"></div>
                  </div>
                </td>
                <td class="d-block d-sm-table-cell">
                  {{ item.value }}
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td
                    class="d-block d-sm-table-cell"
                    colspan="3"
                    style="
                      text-align: center;
                      padding: 0 15px;
                      font-weight: normal;
                    "
                >
                  Sorry no data for you
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="statistics">
          <div class="statistics__statistics" :style="`width:${isMobile?'100%':'calc(66.66% + 20px)'};`">
            <v-autocomplete
                class="statistics__type"
:style="`width:${isMobile?'150px':'320px'};`"
                solo
                flat
                dense
                outlined
                :placeholder="$t('statistics[\'Открытия\']')"
                :items="
                Object.keys(platforms).map((key) => ({
                  platform: key,
                  name: key,
                }))
              "
                item-value="browser"
                item-text="name"
                v-model="current_platform"
                @change="changePlatform"
            >
              <template v-slot:selection="{ item }">
                <div v-if="item.platform === 'All'">
                  {{ item.platform }}
                </div>
                <div style="display: flex; align-items: center" v-else>
                  <div>{{ item.platform }}</div>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div v-if="item.platform === 'All'">
                  {{ item.platform }}
                </div>
                <div style="display: flex; align-items: center" v-else>
                  <div>{{ item.platform }}</div>
                </div>
              </template>
            </v-autocomplete>
            <div class="statistics__chart">
              <Chart
                  style="height:415px"
                  :height="415"
                  :data="platforms[current_platform]"
                  :labels="dateArr"
                  :pointRadius="4"
                  :xAxes_ticks="true"
              />
            </div>
          </div>
          <div class="statistics__info"
v-if="!isMobile">
            <h3 class="statictics__caption">
              {{ $t('statistics["Количество посещений"]') }}
            </h3>
            <p class="statistics__period">
              <span>{{ visitors_platform_statistics }}</span>
              for {{ current_platform }}
            </p>
            <p class="statistics__total">
              <span
              >The graph shows which operating system or mobile phone your
                guests use</span
              >
            </p>
          </div>
        </div>
        <div style="margin-bottom: 15px">
          <v-data-table
              class="table"
              :headers="platform_statistics.headers"
              :items="platformStatisticsItems"
              hide-default-footer
              :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="platformStatisticsItems.length">
              <tr v-for="(item, i) in items" :key="i">
                <td class="d-block d-sm-table-cell" style="cursor: pointer">
                  <div style="display: flex; align-items: center">
                    <img
                        :src="
                          require(`@/assets/platform_images/${item.platform.toLowerCase()}.png`)
                        "
                        style="width: 25px; height: 25px; margin-right: 10px"
                        :alt="item.platform"
                    />
                    <div>{{ item.platform }}</div>
                  </div>
                </td>
                <td class="d-block d-sm-table-cell">
                  {{ item.value }}
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td
                    class="d-block d-sm-table-cell"
                    colspan="3"
                    style="
                      text-align: center;
                      padding: 0 15px;
                      font-weight: normal;
                    "
                >
                  Sorry no data for you
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
  import store from "@/store";
  import {mapState,mapActions} from "vuex";
  import titleMixin from "../../../mixins/titleMixin";
  import StatisticsService from "../../../services/statistics.service";
  export default {
    mixins:[titleMixin],
    title(){
      return 'Segment Statistics'
    },
    name: "SegmentStatistics",
    mounted() {

    },
    components:{
      ObjectFilter: () => import("@/components/ObjectFilter.vue"),
      Chart: () => import("@/components/Chart.vue"),
    },
    data(){
      return {
        isMobile:false,
        countries: [
          { value: "AF", label: "Afghanistan" },
          { value: "AX", label: "Åland Islands" },
          { value: "AL", label: "Albania" },
          { value: "DZ", label: "Algeria" },
          { value: "AS", label: "American Samoa" },
          { value: "AD", label: "Andorra" },
          { value: "AO", label: "Angola" },
          { value: "AI", label: "Anguilla" },
          { value: "AQ", label: "Antarctica" },
          { value: "AG", label: "Antigua and Barbuda" },
          { value: "AR", label: "Argentina" },
          { value: "AM", label: "Armenia" },
          { value: "AW", label: "Aruba" },
          { value: "AU", label: "Australia" },
          { value: "AT", label: "Austria" },
          { value: "AZ", label: "Azerbaijan" },
          { value: "BS", label: "Bahamas" },
          { value: "BH", label: "Bahrain" },
          { value: "BD", label: "Bangladesh" },
          { value: "BB", label: "Barbados" },
          { value: "BY", label: "Belarus" },
          { value: "BE", label: "Belgium" },
          { value: "BZ", label: "Belize" },
          { value: "BJ", label: "Benin" },
          { value: "BM", label: "Bermuda" },
          { value: "BT", label: "Bhutan" },
          { value: "BO", label: "Bolivia, Plurinational State of" },
          { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
          { value: "BA", label: "Bosnia and Herzegovina" },
          { value: "BW", label: "Botswana" },
          { value: "BV", label: "Bouvet Island" },
          { value: "BR", label: "Brazil" },
          { value: "IO", label: "British Indian Ocean Territory" },
          { value: "BN", label: "Brunei Darussalam" },
          { value: "BG", label: "Bulgaria" },
          { value: "BF", label: "Burkina Faso" },
          { value: "BI", label: "Burundi" },
          { value: "KH", label: "Cambodia" },
          { value: "CM", label: "Cameroon" },
          { value: "CA", label: "Canada" },
          { value: "CV", label: "Cape Verde" },
          { value: "KY", label: "Cayman Islands" },
          { value: "CF", label: "Central African Republic" },
          { value: "TD", label: "Chad" },
          { value: "CL", label: "Chile" },
          { value: "CN", label: "China" },
          { value: "CX", label: "Christmas Island" },
          { value: "CC", label: "Cocos (Keeling) Islands" },
          { value: "CO", label: "Colombia" },
          { value: "KM", label: "Comoros" },
          { value: "CG", label: "Congo" },
          { value: "CD", label: "Congo, the Democratic Republic of the" },
          { value: "CK", label: "Cook Islands" },
          { value: "CR", label: "Costa Rica" },
          { value: "CI", label: "Côte d'Ivoire" },
          { value: "HR", label: "Croatia" },
          { value: "CU", label: "Cuba" },
          { value: "CW", label: "Curaçao" },
          { value: "CY", label: "Cyprus" },
          { value: "CZ", label: "Czech Republic" },
          { value: "DK", label: "Denmark" },
          { value: "DJ", label: "Djibouti" },
          { value: "DM", label: "Dominica" },
          { value: "DO", label: "Dominican Republic" },
          { value: "EC", label: "Ecuador" },
          { value: "EG", label: "Egypt" },
          { value: "SV", label: "El Salvador" },
          { value: "GQ", label: "Equatorial Guinea" },
          { value: "ER", label: "Eritrea" },
          { value: "EE", label: "Estonia" },
          { value: "ET", label: "Ethiopia" },
          { value: "FK", label: "Falkland Islands (Malvinas)" },
          { value: "FO", label: "Faroe Islands" },
          { value: "FJ", label: "Fiji" },
          { value: "FI", label: "Finland" },
          { value: "FR", label: "France" },
          { value: "GF", label: "French Guiana" },
          { value: "PF", label: "French Polynesia" },
          { value: "TF", label: "French Southern Territories" },
          { value: "GA", label: "Gabon" },
          { value: "GM", label: "Gambia" },
          { value: "GE", label: "Georgia" },
          { value: "DE", label: "Germany" },
          { value: "GH", label: "Ghana" },
          { value: "GI", label: "Gibraltar" },
          { value: "GR", label: "Greece" },
          { value: "GL", label: "Greenland" },
          { value: "GD", label: "Grenada" },
          { value: "GP", label: "Guadeloupe" },
          { value: "GU", label: "Guam" },
          { value: "GT", label: "Guatemala" },
          { value: "GG", label: "Guernsey" },
          { value: "GN", label: "Guinea" },
          { value: "GW", label: "Guinea-Bissau" },
          { value: "GY", label: "Guyana" },
          { value: "HT", label: "Haiti" },
          { value: "HM", label: "Heard Island and McDonald Mcdonald Islands" },
          { value: "VA", label: "Holy See (Vatican City State)" },
          { value: "HN", label: "Honduras" },
          { value: "HK", label: "Hong Kong" },
          { value: "HU", label: "Hungary" },
          { value: "IS", label: "Iceland" },
          { value: "IN", label: "India" },
          { value: "ID", label: "Indonesia" },
          { value: "IR", label: "Iran, Islamic Republic of" },
          { value: "IQ", label: "Iraq" },
          { value: "IE", label: "Ireland" },
          { value: "IM", label: "Isle of Man" },
          { value: "IL", label: "Israel" },
          { value: "IT", label: "Italy" },
          { value: "JM", label: "Jamaica" },
          { value: "JP", label: "Japan" },
          { value: "JE", label: "Jersey" },
          { value: "JO", label: "Jordan" },
          { value: "KZ", label: "Kazakhstan" },
          { value: "KE", label: "Kenya" },
          { value: "KI", label: "Kiribati" },
          { value: "KP", label: "Korea, Democratic People's Republic of" },
          { value: "KR", label: "Korea, Republic of" },
          { value: "KW", label: "Kuwait" },
          { value: "KG", label: "Kyrgyzstan" },
          { value: "LA", label: "Lao People's Democratic Republic" },
          { value: "LV", label: "Latvia" },
          { value: "LB", label: "Lebanon" },
          { value: "LS", label: "Lesotho" },
          { value: "LR", label: "Liberia" },
          { value: "LY", label: "Libya" },
          { value: "LI", label: "Liechtenstein" },
          { value: "LT", label: "Lithuania" },
          { value: "LU", label: "Luxembourg" },
          { value: "MO", label: "Macao" },
          { value: "MK", label: "Macedonia, the Former Yugoslav Republic of" },
          { value: "MG", label: "Madagascar" },
          { value: "MW", label: "Malawi" },
          { value: "MY", label: "Malaysia" },
          { value: "MV", label: "Maldives" },
          { value: "ML", label: "Mali" },
          { value: "MT", label: "Malta" },
          { value: "MH", label: "Marshall Islands" },
          { value: "MQ", label: "Martinique" },
          { value: "MR", label: "Mauritania" },
          { value: "MU", label: "Mauritius" },
          { value: "YT", label: "Mayotte" },
          { value: "MX", label: "Mexico" },
          { value: "FM", label: "Micronesia, Federated States of" },
          { value: "MD", label: "Moldova, Republic of" },
          { value: "MC", label: "Monaco" },
          { value: "MN", label: "Mongolia" },
          { value: "ME", label: "Montenegro" },
          { value: "MS", label: "Montserrat" },
          { value: "MA", label: "Morocco" },
          { value: "MZ", label: "Mozambique" },
          { value: "MM", label: "Myanmar" },
          { value: "NA", label: "Namibia" },
          { value: "NR", label: "Nauru" },
          { value: "NP", label: "Nepal" },
          { value: "NL", label: "Netherlands" },
          { value: "NC", label: "New Caledonia" },
          { value: "NZ", label: "New Zealand" },
          { value: "NI", label: "Nicaragua" },
          { value: "NE", label: "Niger" },
          { value: "NG", label: "Nigeria" },
          { value: "NU", label: "Niue" },
          { value: "NF", label: "Norfolk Island" },
          { value: "MP", label: "Northern Mariana Islands" },
          { value: "NO", label: "Norway" },
          { value: "OM", label: "Oman" },
          { value: "PK", label: "Pakistan" },
          { value: "PW", label: "Palau" },
          { value: "PS", label: "Palestine, State of" },
          { value: "PA", label: "Panama" },
          { value: "PG", label: "Papua New Guinea" },
          { value: "PY", label: "Paraguay" },
          { value: "PE", label: "Peru" },
          { value: "PH", label: "Philippines" },
          { value: "PN", label: "Pitcairn" },
          { value: "PL", label: "Poland" },
          { value: "PT", label: "Portugal" },
          { value: "PR", label: "Puerto Rico" },
          { value: "QA", label: "Qatar" },
          { value: "RE", label: "Réunion" },
          { value: "RO", label: "Romania" },
          { value: "RU", label: "Russian Federation" },
          { value: "RW", label: "Rwanda" },
          { value: "BL", label: "Saint Barthélemy" },
          { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
          { value: "KN", label: "Saint Kitts and Nevis" },
          { value: "LC", label: "Saint Lucia" },
          { value: "MF", label: "Saint Martin (French part)" },
          { value: "PM", label: "Saint Pierre and Miquelon" },
          { value: "VC", label: "Saint Vincent and the Grenadines" },
          { value: "WS", label: "Samoa" },
          { value: "SM", label: "San Marino" },
          { value: "ST", label: "Sao Tome and Principe" },
          { value: "SA", label: "Saudi Arabia" },
          { value: "SN", label: "Senegal" },
          { value: "RS", label: "Serbia" },
          { value: "SC", label: "Seychelles" },
          { value: "SL", label: "Sierra Leone" },
          { value: "SG", label: "Singapore" },
          { value: "SX", label: "Sint Maarten (Dutch part)" },
          { value: "SK", label: "Slovakia" },
          { value: "SI", label: "Slovenia" },
          { value: "SB", label: "Solomon Islands" },
          { value: "SO", label: "Somalia" },
          { value: "ZA", label: "South Africa" },
          { value: "GS", label: "South Georgia and the South Sandwich Islands" },
          { value: "SS", label: "South Sudan" },
          { value: "ES", label: "Spain" },
          { value: "LK", label: "Sri Lanka" },
          { value: "SD", label: "Sudan" },
          { value: "SR", label: "Suriname" },
          { value: "SJ", label: "Svalbard and Jan Mayen" },
          { value: "SZ", label: "Swaziland" },
          { value: "SE", label: "Sweden" },
          { value: "CH", label: "Switzerland" },
          { value: "SY", label: "Syrian Arab Republic" },
          { value: "TW", label: "Taiwan, Province of China" },
          { value: "TJ", label: "Tajikistan" },
          { value: "TZ", label: "Tanzania, United Republic of" },
          { value: "TH", label: "Thailand" },
          { value: "TL", label: "Timor-Leste" },
          { value: "TG", label: "Togo" },
          { value: "TK", label: "Tokelau" },
          { value: "TO", label: "Tonga" },
          { value: "TT", label: "Trinidad and Tobago" },
          { value: "TN", label: "Tunisia" },
          { value: "TR", label: "Turkey" },
          { value: "TM", label: "Turkmenistan" },
          { value: "TC", label: "Turks and Caicos Islands" },
          { value: "TV", label: "Tuvalu" },
          { value: "UG", label: "Uganda" },
          { value: "UA", label: "Ukraine" },
          { value: "AE", label: "United Arab Emirates" },
          { value: "GB", label: "United Kingdom" },
          { value: "US", label: "United States" },
          { value: "UM", label: "United States Minor Outlying Islands" },
          { value: "UY", label: "Uruguay" },
          { value: "UZ", label: "Uzbekistan" },
          { value: "VU", label: "Vanuatu" },
          { value: "VE", label: "Venezuela, Bolivarian Republic of" },
          { value: "VN", label: "Viet Nam" },
          { value: "VG", label: "Virgin Islands, British" },
          { value: "VI", label: "Virgin Islands, U.S." },
          { value: "WF", label: "Wallis and Futuna" },
          { value: "EH", label: "Western Sahara" },
          { value: "YE", label: "Yemen" },
          { value: "ZM", label: "Zambia" },
          { value: "ZW", label: "Zimbabwe" },
        ],
        segmentTable: {
          headers: [
            {text: "Segment", value: "segment"},
            {text: "Visitors", value: "value"},
          ],
          items: [],
        },
        hotelName:"",
        dateArr:[],
        current_segment:"All",
        segmentsListWithData: {},
        statistics: [],
        chart_data:[],
        all_visitors:0,
        total_visitors:0,


        browser_statistics: {
          headers: [
            { text: "Browser", value: "browser" },
            { text: "Opens", value: "value" },
          ],
          items: [],
        },
        browsers: {},
        current_browser: "All",
        chart_data_browser_visitor: [],
        visitors_browser_statistics: 0,
        total_visitors_browser_statistics: 0,


        platform_statistics: {
          headers: [
            { text: "Platform", value: "platform" },
            { text: "Opens", value: "value" },
          ],
          items: [],
        },
        current_platform: "All",
        platforms: {},
        chart_data_platform_visitor: [],
        visitors_platform_statistics: 0,
        total_visitors_platform_statistics: 0,

        browser_lang_statistics: {
          headers: [
            { text: "Platform", value: "platform" },
            { text: "Opens", value: "value" },
          ],
          items: [],
        },
        current_browser_lang: "All",
        browser_langs: {},
        chart_data_browser_lang_visitor: [],
        visitors_browser_lang_statistics: 0,
        total_visitors_browser_lang_statistics: 0,
      }
    },
    created() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    computed:{
      ...mapState(["objects"]),
      ...mapState('segments',['segments']),
      platformStatisticsItems: function () {
        if (this.current_platform !== null) {
          const returnInfo = [];
          for (let i in this.platforms) {
            if (i !== "All") {
              const platforms = {
                platform: "",
                value: 0,
              };
              platforms.platform = i;
              platforms.value = this.platforms[i].reduce((acc, item) => {
                acc += item;
                return acc;
              }, 0);
              returnInfo.push(platforms);
            }
          }
          return returnInfo;
        } else {
          return this.platforms.All;
        }
      },
      languagesStatisticsItems: function () {
        if (this.current_browser !== null) {
          const returnInfo = [];
          for (let i in this.browser_langs) {
            if (i !== "All") {
              const browsers = {
                browser_lang: "",
                value: 0,
              };
              browsers.browser_lang = i;
              browsers.value = this.browser_langs[i].reduce((acc, item) => {
                acc += item;
                return acc;
              }, 0);
              returnInfo.push(browsers);
            }
          }
          return returnInfo;
        } else {
          return this.browser_langs.All;
        }
      },
      browserStatisticsItems: function () {
        if (this.current_browser !== null) {
          const returnInfo = [];
          for (let i in this.browsers) {
            if (i !== "All") {
              const browsers = {
                browser: "",
                value: 0,
              };
              browsers.browser = i;
              browsers.value = this.browsers[i].reduce((acc, item) => {
                acc += item;
                return acc;
              }, 0);
              returnInfo.push(browsers);
            }
          }
          return returnInfo;
        } else {
          return this.browsers.All;
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      if (!store.state.objects.list.length) {
        store
          .dispatch("objects/getList")
          .then((data) => {
            next();
          })
          .catch(() => {
            next();
          });
      } else {
        next();
      }
    },
    methods:{
      ...mapActions('segments',['getSegments']),
      changeFormatLangPic(lang) {
        const langArr = lang.split("-");
        if (langArr[1]) {
          const candidate = this.countries.find(
            (x) => x.value === langArr[1].toUpperCase()
          );
          if (candidate) {
            return candidate.value;
          }
        } else {
          return lang.toUpperCase();
        }
      },
      changeLanguage(){
        this.visitors_browser_lang_statistics = this.browser_langs[this.current_browser_lang].reduce(
          (acc, item) => {
            acc += item;
            return acc;
          },
          0
        );
      },
      changePlatform(){
        this.visitors_platform_statistics = this.platforms[this.current_platform].reduce(
          (acc, item) => {
            acc += item;
            return acc;
          },
          0
        );
      },
      changeBrowser() {
        this.visitors_browser_statistics = this.browsers[this.current_browser].reduce(
          (acc, item) => {
            acc += item;
            return acc;
          },
          0
        );
      },
      segmentName(id){
        if(id === 'All'){
          return 'All'
        }
        const candidate = this.segments.find(x => x.id === +id);
        if(candidate){
          return candidate.name
        }else{
          return ''
        }
      },
      changeCurrentSegment(){
        this.chart_data = this.segmentsListWithData[this.current_segment];
        this.all_visitors = this.segmentsListWithData[this.current_segment].reduce((acc,item) => {
          acc = acc+item;
          return acc;
        },0);

      },
      async reloadData() {
        await this.getStatistics();
      },
      formatDateForStatistics(date) {
        const dateArr = date.split(".");
        return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
      },
      async changeObject(id) {
        this.$store.state.statistics.object_id = id;
        if (
          !this.$store.state.statistics.date_start &&
          !this.$store.state.statistics.date_end
        ) {
          let end = this.$moment().format("YYYY-MM-DD");
          let start = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
          this.$store.state.statistics.date_start =
            this.$moment(start).format("DD.MM.YYYY");
          this.$store.state.statistics.date_end =
            this.$moment(end).format("DD.MM.YYYY");
        }
        await this.getStatistics();
      },
      changeFormat(lang) {
        const langArr = lang.split("-");
        if (langArr[1]) {
          const candidate = this.countries.find(
            (x) => x.value === langArr[1].toUpperCase()
          );
          if (candidate) {
            return `${candidate.label} <span style="color:#ccc;">(${lang})</span>`;
          }
        } else {
          const candidate = this.countries.find(
            (x) => x.value === langArr[0].toUpperCase()
          );
          return `${
            candidate.label
          } <span style="color:#ccc;">(${lang}-${lang.toUpperCase()})</span>`;
        }
      },
      async changeDates(dates) {
        this.labels = [];
        if (dates.date_start && dates.date_end) {
          this.$store.state.statistics.date_start = this.$moment
            .unix(dates.date_start)
            .format("DD.MM.YYYY");
          this.$store.state.statistics.date_end = this.$moment
            .unix(dates.date_end)
            .format("DD.MM.YYYY");
        } else {
          this.$store.state.statistics.date_start = dates.date_start;
          this.$store.state.statistics.date_end = dates.date_end;
        }
        await this.getStatistics();
      },
      downloadStatistics(){

      },
      formatDateForChart(date) {
        if (date.includes("-")) {
          const dateArr = date.split("-");
          return `${dateArr[2]}.${dateArr[1]}`;
        } else {
          return date;
        }
      },
      async getStatistics(){
        this.hotelName = this.objects.list.find((x) => x.id === this.$store.state.statistics.object_id).name;
        await this.getSegments(this.$store.state.statistics.object_id);
        const payload = {
          hotel_id: this.$store.state.statistics.object_id,
          date_start: this.formatDateForStatistics(
            this.$store.state.statistics.date_start
          ),
          date_end: this.formatDateForStatistics(
            this.$store.state.statistics.date_end
          ),
        };
        this.statistics = await StatisticsService.getSegmentStatistics(payload);
        this.segmentsListWithData = { All: [] };
        this.browsers = { All: [] };
        this.browser_langs = { All: [] };
        this.platforms = { All: [] };
        this.dateArr = [];
        this.segmentTable.items = [];
        this.all_visitors = 0;
        this.visitors_browser_statistics = 0;

        for (let i in this.statistics) {
          if (this.statistics[i]) {
            for (let key in this.statistics[i]) {
              if (!this.segmentsListWithData[key]) {
                this.segmentsListWithData[key] = [];
                this.segmentTable.items.push({
                  id:key,
                  segment:this.segmentName(key),
                  value:0
                })
              }
              if (!this.browsers[this.statistics[i][key].browser]) {
                this.browsers[this.statistics[i][key].browser] = [];
              }
              if (!this.browser_langs[this.statistics[i][key].browser_lang]) {
                this.browser_langs[this.statistics[i][key].browser_lang] = [];
              }
              if (!this.platforms[this.statistics[i][key].platform]) {
                this.platforms[this.statistics[i][key].platform] = [];
              }
            }
          }
        }
        let ind = 0;
        for (let i in this.statistics) {
          this.dateArr.push(this.formatDateForChart(i));
          if (this.statistics[i]) {
            for (let a in this.browsers) {
              this.browsers[a].push(0);
            }
            for (let a in this.platforms) {
              this.platforms[a].push(0);
            }
            for (let a in this.browser_langs) {
              this.browser_langs[a].push(0);
            }
            this.segmentsListWithData.All.push(0);
            for (let key in this.statistics[i]) {
              this.browsers.All[ind] += this.statistics[i][key].count;
              for (let browserIndex in this.browsers) {
                if (this.statistics[i][key].browser === browserIndex) {
                  this.browsers[browserIndex][ind] += this.statistics[i][key].count;
                }
              }
              for (let browserLang in this.browser_langs) {
                this.browser_langs.All[ind] += this.statistics[i][key].count;
                if (this.statistics[i][key].browser_lang === browserLang) {
                  this.browser_langs[browserLang][ind] = this.browser_langs[browserLang][ind]+this.statistics[i][key].count;
                }
              }
              for (let platform in this.platforms) {
                this.platforms.All[ind] += this.statistics[i][key].count;
                if (this.statistics[i][key].platform === platform) {
                  this.platforms[platform][ind] = this.platforms[platform][ind]+this.statistics[i][key].count;
                }
              }
              this.segmentsListWithData[key].push(this.statistics[i][key].count);
              this.segmentTable.items.find(x => x.id === key).value = this.segmentTable.items.find(x => x.id === key).value+this.statistics[i][key].count;
              this.segmentsListWithData.All[ind] += this.statistics[i][key].count;
            }
          }else{
            for (let key in this.segmentsListWithData) {
              this.segmentsListWithData[key].push(0);
            }
            for (let a in this.browsers) {
              this.browsers[a].push(0);
            }
            for (let a in this.browser_langs) {
              this.browser_langs[a].push(0);
            }
            for (let a in this.platforms) {
              this.platforms[a].push(0);
            }
          }
          ind++;
        }
        this.chart_data = this.segmentsListWithData.All;
        this.total_visitors = this.segmentsListWithData.All.reduce((acc, item) => {
          acc += item;
          return acc;
        }, 0);
        this.all_visitors = this.total_visitors;
        this.visitors_browser_statistics = this.total_visitors;
        this.visitors_browser_lang_statistics = this.total_visitors;
        this.visitors_platform_statistics = this.total_visitors;
      }
    }
  }
</script>

<style scoped lang="scss">

  .statistics {
    @include box;
    display: flex;
    // align-items: flex-end;
    margin-bottom: 30px;

    canvas {
      width: 100% !important;
      height: 300px !important;
    }
  }

  .statistics__statistics {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(66.66% + 20px);
  }

  .statistics__type {
    position: absolute;
    width: 320px;
    top: 30px;
    left: 60px;
  }

  .statistics__chart {
    margin-top: auto;
  }

  .statistics__info {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 20px);
  }

  .statictics__caption {
    font-size: 16px;
    font-weight: 600;
    padding: 40px 40px 0;
  }

  .statistics__period {
    margin-bottom: auto;
    padding: 40px;
    font-weight: 500;
    font-size: 14px;
    color: #7f8385;
    span {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 80px;
      line-height: 1.21;
      color: #007db5;
      white-space: nowrap;
    }
  }

  .statistics__total {
    margin-top: auto;
    padding: 40px;
    border-radius: 0 0 5px 0;
    background: rgb(190, 193, 194);
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    span {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.22;
    }
  }

  .statistics__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  .statistics canvas{
    height: 415px !important;
  }
</style>
