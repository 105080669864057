import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"heading"},[_vm._v("Segment statistics")]),_c('ObjectFilter',{attrs:{"object_id":_vm.$store.state.statistics.object_id,"objects":_vm.objects.list},on:{"changeObject":_vm.changeObject,"changeDates":_vm.changeDates,"reloadData":_vm.reloadData}}),_c(VTabs,[_c(VTab,[_vm._v("All Visits")]),_c(VTab,[_vm._v("Browsers")]),_c(VTab,[_vm._v("Languages")]),_c(VTab,[_vm._v("Platforms")]),_c(VTabItem,[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"statistics__statistics",style:(("width:" + (_vm.isMobile?'100%':'calc(66.66% + 20px)') + ";"))},[_c(VAutocomplete,{staticClass:"statistics__type",style:(("width:" + (_vm.isMobile?'150px':'320px') + ";")),attrs:{"solo":"","flat":"","dense":"","outlined":"","placeholder":_vm.$t('statistics[\'Открытия\']'),"items":Object.keys(_vm.segmentsListWithData).map(function (key) { return ({ segment: key, name: key }); }),"item-value":"page","item-text":"name"},on:{"change":_vm.changeCurrentSegment},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.segmentName(item.segment))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.segmentName(item.segment))+" ")]}}]),model:{value:(_vm.current_segment),callback:function ($$v) {_vm.current_segment=$$v},expression:"current_segment"}}),_c('div',{staticClass:"statistics__chart"},[_c('Chart',{staticStyle:{"height":"415px"},attrs:{"height":415,"data":_vm.chart_data,"labels":_vm.dateArr,"pointRadius":4,"xAxes_ticks":true}})],1)],1),(!_vm.isMobile)?_c('div',{staticClass:"statistics__info"},[_c('h3',{staticClass:"statictics__caption"},[_vm._v(" Users with segment for period ")]),_c('p',{staticClass:"statistics__period"},[_c('span',[_vm._v(_vm._s(_vm.all_visitors))]),_vm._v(" "+_vm._s(_vm.$t('statistics["за текущий период"]'))+" ")]),_c('p',{staticClass:"statistics__total"},[_c('span',[_vm._v("The graph shows the number of visits with segments to your mobile application: "+_vm._s(_vm.hotelName))])])]):_vm._e()]),_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c(VDataTable,{staticClass:"table",attrs:{"headers":_vm.segmentTable.headers,"items":_vm.segmentTable.items,"hide-default-footer":"","items-per-page":999},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(_vm.segmentTable.items.length)?_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',{staticClass:"d-block d-sm-table-cell"},[_vm._v(" "+_vm._s(item.segment)+" ")]),_c('td',{staticClass:"d-block d-sm-table-cell"},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center","padding":"0 15px","font-weight":"normal"},attrs:{"colspan":"3"}},[_vm._v(" Sorry no data for you ")])])])]}}])})],1),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":""},on:{"click":_vm.downloadStatistics}},[_vm._v(" Download csv ")])],1),_c(VTabItem,[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"statistics__statistics",style:(("width:" + (_vm.isMobile?'100%':'calc(66.66% + 20px)') + ";"))},[_c(VSelect,{staticClass:"statistics__type",style:(("width:" + (_vm.isMobile?'150px':'320px') + ";")),attrs:{"solo":"","flat":"","dense":"","outlined":"","placeholder":_vm.$t('statistics[\'Открытия\']'),"items":Object.keys(_vm.browsers).map(function (key) { return ({
                  browser: key,
                  name: key,
                }); }),"item-value":"browser","item-text":"name"},on:{"change":_vm.changeBrowser},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [(item.browser === 'All')?_c('div',[_vm._v(" "+_vm._s(item.browser)+" ")]):_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"25px","height":"25px","margin-right":"10px"},attrs:{"src":require(("@/assets/browser_images/" + (item.browser.toLowerCase()) + ".png")),"alt":item.browser}}),_c('div',[_vm._v(_vm._s(item.browser))])])]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [(item.browser === 'All')?_c('div',[_vm._v(" "+_vm._s(item.browser)+" ")]):_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"25px","height":"25px","margin-right":"10px"},attrs:{"src":require(("@/assets/browser_images/" + (item.browser.toLowerCase()) + ".png")),"alt":item.browser}}),_c('div',[_vm._v(_vm._s(item.browser))])])]}}]),model:{value:(_vm.current_browser),callback:function ($$v) {_vm.current_browser=$$v},expression:"current_browser"}}),_c('div',{staticClass:"statistics__chart"},[_c('Chart',{staticStyle:{"height":"415px"},attrs:{"height":415,"data":_vm.browsers[_vm.current_browser],"labels":_vm.dateArr,"pointRadius":4,"xAxes_ticks":true}})],1)],1),(!_vm.isMobile)?_c('div',{staticClass:"statistics__info"},[_c('h3',{staticClass:"statictics__caption"},[_vm._v(" Users by browser with segment for period ")]),_c('p',{staticClass:"statistics__period"},[_c('span',[_vm._v(_vm._s(_vm.visitors_browser_statistics))]),_vm._v(" "+_vm._s(_vm.$t('statistics["за текущий период"]'))+" ")]),_c('p',{staticClass:"statistics__total"},[_c('span',[_vm._v("The graph shows the number of visits by browser with segments to your mobile application: "+_vm._s(_vm.hotelName))])])]):_vm._e()]),_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c(VDataTable,{staticClass:"table",attrs:{"headers":_vm.browser_statistics.headers,"items":_vm.browserStatisticsItems,"hide-default-footer":"","items-per-page":999},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                var items = ref.items;
return [(_vm.browserStatisticsItems.length)?_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"25px","height":"25px","margin-right":"10px"},attrs:{"src":require(("@/assets/browser_images/" + (item.browser.toLowerCase()) + ".png")),"alt":item.browser}}),_c('div',[_vm._v(_vm._s(item.browser))])])]),_c('td',{staticClass:"d-block d-sm-table-cell"},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center","padding":"0 15px","font-weight":"normal"},attrs:{"colspan":"3"}},[_vm._v(" Sorry no data for you ")])])])]}}])})],1)]),_c(VTabItem,[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"statistics__statistics",style:(("width:" + (_vm.isMobile?'100%':'calc(66.66% + 20px)') + ";"))},[_c(VAutocomplete,{staticClass:"statistics__type",style:(("width:" + (_vm.isMobile?'150px':'320px') + ";")),attrs:{"solo":"","flat":"","dense":"","outlined":"","placeholder":"Change browser language","items":Object.keys(_vm.browser_langs).map(function (key) { return ({
                  lang: key,
                  name: key,
                }); }),"item-value":"lang","item-text":"name"},on:{"change":_vm.changeLanguage},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [(item.lang === 'All')?_c('div',[_vm._v(" "+_vm._s(item.lang)+" ")]):_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"25px","height":"25px","margin-right":"10px"},attrs:{"src":("https://guest.mobi/3x2/" + (_vm.changeFormatLangPic(
                      item.lang
                    )) + ".svg"),"alt":item.lang}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.changeFormat(item.lang))}})])]}},{key:"item",fn:function(ref){
                    var item = ref.item;
return [(item.lang === 'All')?_c('div',[_vm._v(" "+_vm._s(item.lang)+" ")]):_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"25px","height":"25px","margin-right":"10px"},attrs:{"src":("https://guest.mobi/3x2/" + (_vm.changeFormatLangPic(
                      item.lang
                    )) + ".svg"),"alt":item.lang}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.changeFormat(item.lang))}})])]}}]),model:{value:(_vm.current_browser_lang),callback:function ($$v) {_vm.current_browser_lang=$$v},expression:"current_browser_lang"}}),_c('div',{staticClass:"statistics__chart"},[_c('Chart',{staticStyle:{"height":"415px"},attrs:{"height":415,"data":_vm.browsers[_vm.current_browser_lang],"labels":_vm.dateArr,"pointRadius":4,"xAxes_ticks":true}})],1)],1),(!_vm.isMobile)?_c('div',{staticClass:"statistics__info"},[_c('h3',{staticClass:"statictics__caption"},[_vm._v(" "+_vm._s(_vm.$t('statistics["Количество посещений"]'))+" ")]),_c('p',{staticClass:"statistics__period"},[_c('span',[_vm._v(_vm._s(_vm.visitors_browser_lang_statistics))]),_vm._v(" for "+_vm._s(_vm.current_browser_lang)+" ")]),_c('p',{staticClass:"statistics__total"},[_c('span',[_vm._v("The graph shows what language your guests speak, what is their main language")])])]):_vm._e()]),_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c(VDataTable,{staticClass:"table",attrs:{"headers":_vm.browser_lang_statistics.headers,"items":_vm.languagesStatisticsItems,"hide-default-footer":"","items-per-page":999},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var items = ref.items;
return [(_vm.languagesStatisticsItems.length)?_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"25px","height":"25px","margin-right":"10px"},attrs:{"src":("https://guest.mobi/3x2/" + (_vm.changeFormatLangPic(
                          item.browser_lang
                        )) + ".svg"),"alt":item.browser_lang}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.changeFormat(item.browser_lang))}})])]),_c('td',{staticClass:"d-block d-sm-table-cell"},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center","padding":"0 15px","font-weight":"normal"},attrs:{"colspan":"3"}},[_vm._v(" Sorry no data for you ")])])])]}}])})],1)]),_c(VTabItem,[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"statistics__statistics",style:(("width:" + (_vm.isMobile?'100%':'calc(66.66% + 20px)') + ";"))},[_c(VAutocomplete,{staticClass:"statistics__type",style:(("width:" + (_vm.isMobile?'150px':'320px') + ";")),attrs:{"solo":"","flat":"","dense":"","outlined":"","placeholder":_vm.$t('statistics[\'Открытия\']'),"items":Object.keys(_vm.platforms).map(function (key) { return ({
                  platform: key,
                  name: key,
                }); }),"item-value":"browser","item-text":"name"},on:{"change":_vm.changePlatform},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [(item.platform === 'All')?_c('div',[_vm._v(" "+_vm._s(item.platform)+" ")]):_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_vm._v(_vm._s(item.platform))])])]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [(item.platform === 'All')?_c('div',[_vm._v(" "+_vm._s(item.platform)+" ")]):_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_vm._v(_vm._s(item.platform))])])]}}]),model:{value:(_vm.current_platform),callback:function ($$v) {_vm.current_platform=$$v},expression:"current_platform"}}),_c('div',{staticClass:"statistics__chart"},[_c('Chart',{staticStyle:{"height":"415px"},attrs:{"height":415,"data":_vm.platforms[_vm.current_platform],"labels":_vm.dateArr,"pointRadius":4,"xAxes_ticks":true}})],1)],1),(!_vm.isMobile)?_c('div',{staticClass:"statistics__info"},[_c('h3',{staticClass:"statictics__caption"},[_vm._v(" "+_vm._s(_vm.$t('statistics["Количество посещений"]'))+" ")]),_c('p',{staticClass:"statistics__period"},[_c('span',[_vm._v(_vm._s(_vm.visitors_platform_statistics))]),_vm._v(" for "+_vm._s(_vm.current_platform)+" ")]),_c('p',{staticClass:"statistics__total"},[_c('span',[_vm._v("The graph shows which operating system or mobile phone your guests use")])])]):_vm._e()]),_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c(VDataTable,{staticClass:"table",attrs:{"headers":_vm.platform_statistics.headers,"items":_vm.platformStatisticsItems,"hide-default-footer":"","items-per-page":999},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                var items = ref.items;
return [(_vm.platformStatisticsItems.length)?_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"25px","height":"25px","margin-right":"10px"},attrs:{"src":require(("@/assets/platform_images/" + (item.platform.toLowerCase()) + ".png")),"alt":item.platform}}),_c('div',[_vm._v(_vm._s(item.platform))])])]),_c('td',{staticClass:"d-block d-sm-table-cell"},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center","padding":"0 15px","font-weight":"normal"},attrs:{"colspan":"3"}},[_vm._v(" Sorry no data for you ")])])])]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }